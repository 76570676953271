<template>
    <v-card class="list-item my-4" elavation="0">
        <v-container class="py-3">

            <!-- Edit Controls -->
            <div v-if="canEdit" class="row py-0 px-0" style="background-color: #eeeeee;">
                <v-icon class="handle" size="30" style="cursor: pointer;"> mdi-drag </v-icon>
                <v-spacer />
                <div v-if="editMode=='read'" class="col col-4 col-sm-3 pt-2" style="display:flex">
                    <div class="col col-4 py-0">
                        <CustomIcon v-if="isRanked" @click="editListItem()" toolTip="Edit" glyph="mdi-pencil" /> 
                    </div>
                    <div class="col col-4 py-0">
                        <CustomIcon v-if="isRanked" @click="unRank(item.listItemId)" toolTip="UnRank" color="#cc4444" glyph="mdi-numeric-off" /> 
                        <CustomIcon v-if="!isRanked" @click="reRank(item.listItemId)" toolTip="Add To Ranked Items" glyph="mdi-numeric" /> 
                    </div>
                    <div class="col col-4 py-0">
                        <CustomIcon @click="deleteListItem(item.listItemId)" toolTip="Delete" color="#cc4444" glyph="mdi-delete" /> 
                    </div>
                </div>
                <div v-if="editMode=='write' && listFormat!='Rich Text'" class="col col-4 col-sm-3 pt-2" style="display:flex;">
                    <div class="col col-4 py-0">
                        <CustomIcon @click="saveListItem()" toolTip="Save" glyph="mdi-content-save" /> 
                    </div>
                    <div class="col col-4 py-0">
                        <CustomIcon @click="cancelEdit()" toolTip="Cancel" glyph="mdi-cancel" /> 
                    </div>
                </div>
            </div>

            <!-- Item View -->
            <v-row align="center">
                <v-col class="col-12" style="h3">
                    <ListItemPlainText v-if="listFormat=='Plain Text'" ref="itemEditor" :editMode="editMode" :item="item" :index="index" :refreshList="refreshList" :cancelEdit="cancelEdit" :isRanked="isRanked"/>

                    <ListItemMedia v-if="listFormat=='Media'" ref="itemEditor" :editMode="editMode" :item="item" :index="index" :refreshList="refreshList" :cancelEdit="cancelEdit" :saveListItem="saveListItem" :isRanked="isRanked"/>

                    <ListItemRichText v-if="listFormat=='Rich Text'" ref="itemEditor" :editMode="editMode" :item="item" :index="index" :refreshList="refreshList" :cancelEdit="cancelEdit" :saveListItem="saveListItem" :isRanked="isRanked"/>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>
<script>
    import api from '/src/utils/api';
    // import auth from "/src/utils/auth";

    export default {
        components: {
            CustomIcon: () => import("/src/components/CustomIcon.vue"),
            ListItemPlainText: () => import("/src/views/list/components/ListItemPlainText.vue"),
            ListItemMedia: () => import("/src/views/list/components/ListItemMedia.vue"),
            ListItemRichText: () => import("/src/views/list/components/ListItemRichText.vue"),
        },
        data() {
            return {
                editMode: "read",
                htmlstring: `<p style='border: red solid;'>hi</p>hi`,
                htmlstring2: `<p>defined<span class="ql-size-huge">!!</span></p><p><span class="ql-size-huge">ge</span><span class="ql-size-huge" style="color: rgb(230, 0, 0);">rrrrr</span></p> **`,
                // isViewingSelf: auth.isViewingSelf,
            };
        },
        mounted() {
        },
        methods: {
            deleteListItem(listItemId) {
                api.postAuthOptional('delete-list-item', {listItemId}).then(() => {
                    // Remove the item from clientside array, without refreshing the whole list.
                    this.removeItem(this.index);
                });
            },
            unRank(listItemId) {
                api.postAuthOptional('unrank-list-item', {listItemId}).then(() => {
                    this.refreshList();
                });
            },
            reRank(listItemId) {
                let ranking = 9999;
                api.postAuthOptional('update-list-item-ranking', {listItemId, ranking})
                .then(() => {
                    this.refreshList(ranking);
                });
            },
            editListItem() {
                this.editMode = "write";
            },
            saveListItem() {
                this.$refs.itemEditor.save().then(() => {
                    console.log("listitem save done");
                    this.editMode = "read";
                });
                // let newRanking = this.$refs.itemEditor.editRanking;
                // let newContent = this.$refs.itemEditor.getEditedContent();
                // let rankingHasChanged = newRanking != this.index+1;
                // let contentHasChanged = this.$refs.itemEditor.editContent != this.$refs.itemEditor.originalContent;
                // // console.log("rankingHasChanged",rankingHasChanged, this.$refs.itemEditor.editRanking, "--", this.index+1);
                // console.log("contentHasChanged",contentHasChanged, "--", newContent);
                // if (!rankingHasChanged && !contentHasChanged) {
                //     // console.log("no change");
                //     this.editMode = "read";
                // }
                // else {
                //     api.postAuthRequired('update-list-item', {listItemId:this.item.listItemId, content:newContent, ranking:newRanking})
                //     .then(() => {
                //         this.editMode = "read";

                //         // If just the text changed, don't bother reseting the UI which will move the page around.
                //         if (contentHasChanged)
                //             this.item.content = newContent;
    
                //         // Reload from API to ensure UI is representing same order as database.
                //         if (rankingHasChanged)
                //             this.refreshList(newRanking);
                //     });
                // }
            },
            cancelEdit() {
                this.$refs.itemEditor.resetContent().then(() => {
                    console.log("listitem save done");
                    this.editMode = "read";
                });
                // this.editMode='read';
                // this.$refs.itemEditor.setContent(this.item.content);
            },
        },
        props: ['canEdit','item','index', 'listFormat','removeItem','refreshList','isRanked'],
    };
</script>
<style scoped>
.h3 { 
    font-size: 1.17em;   
    font-weight: bold;
}
</style>
